import React, { useState } from "react";
import styled from "styled-components";
import BannerImg2 from "../images/BannerCotizacion.png";
import BannerImg from "../images/BannerFinanciacion.png";
import { useNavigate } from "react-router-dom";
import "../styles.css";

const StyledSliderContent = styled.div`
  display: flex;
  padding: 0 !important;
  margin: 0 !important;
  align-items: center;
  height: 100%;
  position: relative;
  button {
    outline: none;
  }
  /*CodePili*/
  .container-but {
    position: absolute;
    top: 70%;
  }
`;

const StyledImage = styled.img`
  height: fit-content;
  width: 100%;
`;

const StyledButton = styled.button`
  font-family: var(--font-family);

  width: 100%;
`;

const StyledButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
  width: 100%;
  /* margin-bottom: 20px; */

  button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 700;

    cursor: pointer;
    width: 139px;
    border: none;
    outline: none;
  }
  .financiacion {
    border-radius: 6px 0px 0px 6px;
  }
  .cotizacion {
    border-radius: 0px 6px 6px 0px;
  }
  .btn-active {
    background-color: var(--prime-color);
    color: white;
  }

  .btn-disable {
    background-color: var(--gris-claro);
    color: #595e62b2;
  }
`;

const Slide = () => {
  const [currentImage, setCurrentImage] = useState(BannerImg);
  const [activeButton, setActiveButton] = useState("financiacion");
  const navigate = useNavigate();

  const handleFinanciacionClick = () => {
    setCurrentImage(BannerImg);
    setActiveButton("financiacion");
  };

  const handleCotizacionClick = () => {
    setCurrentImage(BannerImg2);
    setActiveButton("cotizacion");
  };

  const handleImageClick = () => {
    // Acción cuando se hace clic en la imagen
    console.log("Clic en la imagen");
  };

  return (
    <div className="container">
      {/*CodePili*/}
      <div className="row">
        <StyledSliderContent>
          <StyledImage
            className="col-md-12"
            src={currentImage}
            alt="Banner Image"
            onClick={handleImageClick}
          />
          <div className="container-but offset-md-9 col-md-2">
            {currentImage === BannerImg && (
              <StyledButton
                className="primary-btn-big"
                onClick={() => navigate("/financiacion")}
              >
                FINANCIÁ YA
              </StyledButton>
            )}
            {currentImage === BannerImg2 && (
              <StyledButton
                className="primary-btn-big"
                onClick={() => navigate("/cotizador")}
              >
                COTIZÁ YA
              </StyledButton>
            )}
          </div>
        </StyledSliderContent>
      </div>
      {/*CodePili*/}
      <div className="row">
        <StyledButtonGroup>
          <button
            className={`financiacion
              ${
                activeButton === "financiacion" ? "btn-active" : "btn-disable"
              }`}
            onClick={handleFinanciacionClick}
          >
            Financiación
          </button>
          <button
            className={`cotizacion
              ${activeButton === "cotizacion" ? "btn-active" : "btn-disable"}`}
            onClick={handleCotizacionClick}
          >
            Cotización
          </button>
        </StyledButtonGroup>
      </div>
    </div>
  );
};

export default Slide;
