import React, { useReducer } from "react";
import styled from "styled-components";

import RamImg from "../images/klipartz.png";
import JeepImg from "../images/logo_jeep.png";
import FiatImg from "../images/logo_fiat.png";
import RenaultImg from "../images/logo_renault.png";
import PeugeotImg from "../images/logo_peugeot.png";
import CitroenImg from "../images/logo_citroen.png";
import ExitImg from "../images/icon_exitmarca.png";

const Container = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  button {
    outline: none;
  }
`;

const MarcaButton = styled.button`
  min-height: 100px;
  min-width: 100px;
  background-image: url(${(props) => props.$backgroundImage});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
  transform: ${(props) =>
    props.notHovered
      ? "scale(0.8) translateX(8px) translateY(8px)"
      : "scale(1)"};
  opacity: ${(props) => (props.faded ? "0.5" : "1")};
  margin-bottom: 10px;

  &:hover {
    transform: scale(1.2);
    perspective: 1000px;
  }
  &.selected {
    transform: scale(1.2);
    opacity: 1;
  }
  @media (max-width: 768px) {
    min-height: 65px;
    min-width: calc(33.33% - 20px);
  }
`;

const ExitButton = styled.img`
  height: 20px;
  width: 20px;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  @media (max-width: 768px) {
    height: 15px;
    width: 15px;
    right: 18px;
  }
`;

const ACTIONS = {
  TOGGLE_MARCA: "TOGGLE_MARCA",
  SET_HOVER: "SET_HOVER",
  UPDATE_FADED: "UPDATE_FADED",
};

const marcasReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.TOGGLE_MARCA:
      const updatedState = state.map((marca) =>
        marca.id === action.payload
          ? { ...marca, selected: !marca.selected }
          : marca
      );
      console.log(updatedState);
      return updatedState.map((marca) => ({
        ...marca,
        faded: updatedState.some((m) => m.selected) && !marca.selected,
      }));
    case ACTIONS.SET_HOVER:
      return state.map((marca) =>
        marca.id === action.payload.id
          ? { ...marca, onHover: action.payload.isHovering }
          : marca
      );
    default:
      return state;
  }
};

const MarcasMenu = ({ setFilterMarcas }) => {
  const [marcasArray, dispatch] = useReducer(marcasReducer, [
    {
      id: 1,
      nombre: "jeep",
      imagen: JeepImg,
      selected: false,
      onHover: false,
      faded: false,
    },
    {
      id: 2,
      nombre: "citroën",
      imagen: CitroenImg,
      selected: false,
      onHover: false,
      faded: false,
    },
    {
      id: 3,
      nombre: "peugeot",
      imagen: PeugeotImg,
      selected: false,
      onHover: false,
      faded: false,
    },
    {
      id: 4,
      nombre: "renault",
      imagen: RenaultImg,
      selected: false,
      onHover: false,
      faded: false,
    },
    {
      id: 5,
      nombre: "fiat",
      imagen: FiatImg,
      selected: false,
      onHover: false,
      faded: false,
    },
    {
      id: 6,
      nombre: "ram",
      imagen: RamImg,
      selected: false,
      onHover: false,
      faded: false,
    },
  ]);

  const updateFilterMarcas = () => {
    const selectedMarcas = marcasArray
      .filter((marca) => marca.selected)
      .map((marca) => marca.nombre.toLowerCase());
      console.log(selectedMarcas);
    setFilterMarcas(selectedMarcas);
  };

  const handleMarcaHover = (id, isHovering) => {
    dispatch({ type: ACTIONS.SET_HOVER, payload: { id, isHovering } });
  };

  const handleMarcaClick = (id) => {
    dispatch({ type: ACTIONS.TOGGLE_MARCA, payload: id });
  };

  React.useEffect(() => {
    updateFilterMarcas();
  }, [marcasArray]);

  return (
    <Container>
      {marcasArray.map((marca) => (
        <MarcaButton
          key={marca.id}
          onMouseEnter={() => handleMarcaHover(marca.id, true)}
          onMouseLeave={() => handleMarcaHover(marca.id, false)}
          onClick={() => handleMarcaClick(marca.id)}
          $backgroundImage={marca.imagen}
          style={{ opacity: marca.faded ? 0.5 : 1 }}
          className={marca.selected ? "selected" : ""}
        >
          {marca.selected && (
            <ExitButton
              src={ExitImg}
              onClick={(e) => {
                e.stopPropagation();
                handleMarcaClick(marca.id);
              }}
            />
          )}
        </MarcaButton>
      ))}
    </Container>
  );
};

export default MarcasMenu;
