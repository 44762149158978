import React, { useEffect, useState } from "react";
import Navbar from "../componentes/Navbar";
import Comparador from "../componentes/Comparador";
import Card from "../componentes/Catalogo0kmCard";
import trama from "../images/trama.png";
import FilterIcon from "../images/filter_icon.png";
import Lupa from "../images/icon_lupa.png";
import BlackArrow from "../images/icon_row_filter.png";
import MarcasMenu from "../componentes/CatalogoNuevosMarcas";
import styled from "styled-components";
import "../styles.css";
import ComparadorMobile from "../componentes/ComparadorMobile";
import axiosInstance from "../utils/ContextAxios";
import Spinner from "../componentes/Loader";
import ModalFiltroMobileNuevos from "../componentes/ModalCatalogoFiltroMobileNuevos";
import ENDPOINTS from "../apiEndpoints";

//agregarle hover a las marcas, las no seleccionadas se encogen...llevan sombrita
const MarginNav = styled.div`
  display: block;
  background-color: black;
  padding-top: 114px;
  @media (max-width: 576px) {
    padding-top: 48px;
  }
`;

const Container = styled.div`
  padding-right: 0px !important;
  padding-left: 0px !important;

  .margin-mobile-comparador {
    display: none;
    width: 100%;
    height: 50px;
  }
  @media (max-width: 576px) {
    .margin-mobile-comparador {
      display: flex;
    }
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 500px;
  font-family: var(--font-family);

  /* padding: 0px !important; */
  @media (max-width: 576px) {
    height: 400px;
    justify-content: flex-start;
    row-gap: 0;
  }
`;

const StyleTitle = styled.div`
  display: flex;
  text-align: left;
  width: 100%;
  font-weight: 700;
  font-size: 33px;
  line-height: 34px;
  margin-top: 100px;

  @media (max-width: 576px) {
    font-size: 21px;
    line-height: 21px;
    text-align: center;
    margin-top: 35px;
    /* padding: 0; */
  }
`;

const ButtonNav = styled.div`
  font-family: var(--font-family);
  display: flex;
  font-size: 18px;
  margin-bottom: 64px;
  width: 100%;

  button {
    background-color: var(--prime-color);
    color: white;
    border-radius: 6px;
    height: 42px;
    font-weight: 700;
    text-align: center;
    border: none;
    line-height: 20px;
    outline: none;
    width: 100%;
  }
  button:hover {
    background-color: #595e62;
  }
  @media (max-width: 576px) {
    display: none;
  }
`;
const ButtonNavMobile = styled.div`
  display: none;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;

  button {
    background-color: transparent;
    color: black;
    border-radius: 6px;
    height: 32px;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    border: none;
    width: 100%;
    line-height: 20px;
    outline: none;
    text-decoration: underline;
  }

  @media (max-width: 576px) {
    display: flex;
    margin-top: 15px;
    button {
      line-height: 13px;
      font-size: 12px;
    }

    img {
      height: 12px;
      width: auto;
      margin-right: 2px;
    }
  }
`;

const Main = styled.div`
  width: 100%;
  @media (max-width: 576px) {
    margin-top: 50px;
  }
`;

/*--------FILTRO DE BUSQUEDA!!!-------------*/
const FilterMobile = styled.div`
  display: none;
  width: 100%;
  margin-top: -20px;
  justify-content: center;

  input {
    width: 246px;
    height: 37px;
    /* box-shadow: 0px 2px 4px 0px #00000035; */
    background-color: var(--gris-invisible);
    padding-left: 48px; /* Ajusta el padding izquierdo para dar espacio al icono */
    background-image: url(${Lupa}); /* Set the background image to the magnifying glass icon */
    background-repeat: no-repeat; /* Prevent the icon from repeating */
    background-position: 15px center; /* Adjust the position of the icon */
    outline: none;
  }
  @media (max-width: 576px) {
    display: flex;
    margin-bottom: 45px;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  align-items: center;
  @media (max-width: 576px) {
    margin: 15px 0;
  }
`;

const FilterIconImage = styled.img`
  display: block;
  width: 15px;
  height: auto;
  padding-top: 13px;
  margin: auto;
  cursor: pointer;
  @media (max-width: 576px) {
    display: none;
  }
`;
const FilterIconImageMobile = styled.img`
  display: none;
  width: 15px;
  height: auto;
  padding-top: 13px;
  margin: auto;
  cursor: pointer;
  @media (max-width: 576px) {
    display: block;
  }
`;
const FilterMenuContainer = styled.div`
  display: ${(props) =>
    props.$showMenu
      ? "block"
      : "none"}; // Mostrar el menú según la prop $showMenu
  position: absolute;
  top: 100%;
  width: 360px;
  font-size: 14px;
  border-radius: 6px;
  left: 0;
  background-color: #f9f9f9;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 6px;

  @media (max-width: 576px) {
    display: none;
  }
`;

const FilterMenuItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px;
  width: 45%;
  border-right: 2px solid #d0d4de;
  cursor: pointer;
  &:hover {
    color: var(--prime-color);
    font-weight: 700;
    img {
      fill: invert(40%);
    }
  }
  @media (max-width: 576px) {
    display: none;
  }
`;

const SubMenu = styled.div`
  display: ${(props) => (props.$showSubMenu ? "block" : "none")};
  position: absolute;
  top: 0%;
  left: 50%;
  padding-top: 5px;
  overflow-y: hidden;
  height: 100%;
`;

const SubMenuItem = styled.div`
  display: flex;
  padding: 6px;
  align-items: center;
  font-weight: 400;
  cursor: pointer;
  &:hover {
    color: var(--prime-color);
    font-weight: 700;
  }
  &.isSelected {
    color: var(--prime-color);
    font-weight: 700;
  }
`;

const StyledFilterMenu = styled.div`
  position: relative;
  height: 43px;
  width: 46px;
  background-color: var(--gris-medio);
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  &:hover {
    ${FilterMenuContainer} {
      display: block;
    }
    transition: all 0.3s ease-out;
    background-color: var(--prime-color);
  }

  @media (max-width: 576px) {
    max-width: 73px;
    width: 100%;
    height: 37px;
    &:hover {
      ${FilterMenuContainer} {
        display: none;
      }
      transition: all 0.3s ease-out;
      background-color: var(--prime-color);
    }
  }
`;
const Filter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
  font-family: var(--font-family);
`;

const SearchInput = styled.input`
  display: flex;
  height: 43px;
  width: 100%;
  background-color: white;
  border: none;
  padding-left: 40px;
  outline: none;
  border-radius: 6px 0px 0px 6px;
  background-image: url(${Lupa});
  background-repeat: no-repeat;
  background-position: 10px center;

  @media (max-width: 576px) {
    width: 246px;
    height: 37px;
  }
`;

/*------------------------------------------*/

const AutoList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 18px;
  width: 100%;
`;

const OtherList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 18px;
  width: 100%;
`;

const SuvList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 18px;
  width: 100%;
`;

const PickupList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  margin-bottom: 18px;
  width: 100%;
`;

const ListTitle = styled.h2`
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  width: 100%;
  margin-left: 15px;
  margin-bottom: 15px;
  @media (max-width: 576px) {
    font-size: 17px;
    line-height: 17px;
  }
`;
const TramaContainer = styled.div`
  background-image: url(${trama});
  background-size: cover;
  background-position: center;
  margin-bottom: 64px;
  @media (max-width: 576px) {
    margin-bottom: 0px;
  }
`;

const Catalago0km = () => {
  //mostrar menu-filter
  const [showMenu, setShowMenu] = useState(false);
  //mostrar menu-filter
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  //mostrar menu-filter
  const [showModalFiltro, setShowModalFiltro] = useState(false);
  //opciones de filtro de busqueda
  const [selectedCategory, setSelectedCategory] = useState(null);
  //opciones de filtro MODELO de busqueda
  const [filterOptionSubMenuActive, setFilterOptionSubMenuActive] = useState(
    []
  );
  const [selectedOptionSubMenu, setSelectedOptionSubMenu] = useState({});
  // Estado para controlar si el comparador está abierto o cerrado
  const [comparadorAbierto, setComparadorAbierto] = useState(false);
  //Estado para filtrar por marcas logo
  const [filterMarcas, setFilterMarcas] = useState([]);
  // Estado para almacenar la información del auto seleccionado
  const [autosSeleccionados, setAutosSeleccionados] = useState([]);
  const [colector, setColector] = useState([]);
  /* ---------------*/
  //Estado del catalogo
  const [startIndex, setStartIndex] = useState(0);
  //producto
  const [autosLista, setAutosLista] = useState([]);

  //estado del filtro

  //opciones del catalogo-filter
  const [catalogoOptionFilter, setCatalogoOptionFilter] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  const [filterOptionsWithModel, setFilterOptionsWithModel] = useState([
    {
      value: "modelo",
      label: "Modelo",
      categories: [],
    },
    {
      value: "fuel",
      label: "Combustible",
      categories: [
        "GNC",
        "Diésel",
        "Nafta",
        "Gasoil",
        "Eléctrico",
        "Biocombustible",
      ],
    },
    {
      value: "traction",
      label: "Tracción",
      categories: ["Delantera", "Trasera", "Total", "4x4"],
    },
    {
      value: "transmission",
      label: "Transmisión",
      categories: ["Automática", "Manual", "Hidráulica", "EVT", "CVT", "DSG"],
    },
    /* {
      value: "doors",
      label: "Cant. de Puertas",
      categories: ["2", "3", "4", "5"],
    }, */
    {
      value: "colors",
      label: "Color",
      categories: ["Rojo", "Azul", "Negro", "Blanco", "Gris", "Verde"],
    },
  ]);

  useEffect(() => {
    console.log("autosSeleccionados updated:", autosSeleccionados);
    console.log("autosSeleccionados updated:", colector);
  }, [colector]);
  
  //Funcion para cargar el auto al comparador y evitar que haga una redireccion
  const handleCardClick = (card) => {
    //console.log(comparadorAbierto, card, autosSeleccionados)
    if (comparadorAbierto && colector.length < 4) {

      setColector(prev => {
        const exists = prev.find(a => a._id === card._id);

        if (!exists) {
          console.log("selectec", [...prev, card]);
          return [...prev, card]; 
        } else {
          return [...prev]
        }
      })

    } else {
      console.log("Redireccionar a la URL del auto:", card._id);
    }
    
  };

  const handleRemoveCar = (id) => {
    const filteredCars = colector.filter(car => car._id !== id)
    setColector([...filteredCars])
  }

  //valor de la busqueda filtro texto barra
  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  //scroll sobre las secciones
  const handleButtonClick = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  //cerrar modal filtro
  const closeModalFiltro = () => {
    setShowModalFiltro(false);
  };
  //manejo del filtro mobile
  const handleSelectedValuesCatalogo = (values) => {
    setCatalogoOptionFilter(values);

    // Obtener campos seleccionados y opciones
    const camposSeleccionados = Object.keys(catalogoOptionFilter);

    const opcionesPorCampo = camposSeleccionados.map((campo) => {
      return {
        campo: campo,
        opciones: catalogoOptionFilter[campo],
      };
    });

    console.log(opcionesPorCampo, "Opciones del filtro"); // Puedes hacer más acciones con esta información
  };

  //Cambiar de opcion en SubMenu del filtro de busqueda
  const handleSubMenuOptionChange = (category, option) => {
    setFilterOptionSubMenuActive((prevActive) => {
      if (prevActive.includes(category)) {
        // Si la categoría ya está seleccionada, quítala
        return prevActive.filter((item) => item !== category);
      } else {
        return [...prevActive, category];
      }
    });
    console.log("log del filtro SUBMENU ", option);
    //-----------------------

    setSelectedOptionSubMenu((prevData) => {
      const categoryValues = prevData[option] || [];
      const newCategoryValues = categoryValues.includes(category)
        ? categoryValues.filter((item) => item !== category)
        : [...categoryValues, category];

      const newSelectedValues = {
        ...prevData,
        [option]: newCategoryValues,
      };
      return newSelectedValues;
    });

    // Obtener campos seleccionados y opciones
    const camposSeleccionados = Object.keys(selectedOptionSubMenu);

    const opcionesFiltroDesktop = camposSeleccionados.map((campo) => {
      return {
        campo: campo,
        opciones: selectedOptionSubMenu[campo],
      };
    });
    console.log(opcionesFiltroDesktop);
    console.log("Filtro Desktop opciones", selectedOptionSubMenu);
  };

  //Evento para los logos de Marcas
  useEffect(() => {
    if (!filterMarcas.length) {
      setFilterOptionsWithModel((prevOptions) =>
        prevOptions.filter((option) => option.value !== "modelo")
      );
      return;
    }

    // Crear un array de todos los filtros de marcas sin acentos y en minúsculas
    const modeloMarcaFilters = filterMarcas.map((marca) =>
      removeAccents(marca.toLowerCase())
    );

    // Filtrar las marcas en autosLista que coincidan con cualquier filtro en modeloMarcaFilters
    const filteredModels = autosLista
      .filter((auto) =>
        modeloMarcaFilters.some((filter) =>
          removeAccents(auto.brand.name.toLowerCase()).includes(filter)
        )
      )
      .map((auto) => auto.modelo.name);

    const uniqueModels = [...new Set(filteredModels)];
    console.log("Modelos filtrados:", filteredModels);
    // Actualizar las opciones en setFilterOptionsWithModel
    setFilterOptionsWithModel((prevOptions) => {
      const existingModelOption = prevOptions.find(
        (option) => option.value === "modelo"
      );

      if (filteredModels.length === 0) {
        // Si no hay modelos y ya existe la opción, la eliminamos
        return existingModelOption
          ? prevOptions.filter((option) => option.value !== "modelo")
          : prevOptions;
      }

      const newModelOption = {
        value: "modelo",
        label: "Modelo",
        categories: uniqueModels,
      };

      // Si hay modelos, asegurarse de que la opción de "Modelo" esté primero
      const optionsWithoutModel = prevOptions.filter(
        (option) => option.value !== "modelo"
      );
      return [newModelOption, ...optionsWithoutModel];
    });
  }, [filterMarcas, autosLista]);

  /*------API-------*/
  useEffect(() => {
    //pedir datos
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(ENDPOINTS.GET_NEW_CARS);
        console.log(response.data);

        setAutosLista(response.data.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, []);

  if (!autosLista || autosLista.length === 0) {
    return <Spinner />; // Mostrar un spinner mientras se cargan los datos
  }

  //quitar acentos en el search
  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const filteredProducts = autosLista.filter((producto) => {
    // Filtro por searchQuery
    const searchQueryMatch =
      removeAccents(producto.modelo.name.toLowerCase()).includes(
        removeAccents(searchQuery.toLowerCase())
      ) ||
      removeAccents(producto.brand.name.toLowerCase()).includes(
        removeAccents(searchQuery.toLowerCase())
      ) ||
      removeAccents(producto.version.name.toLowerCase()).includes(
        removeAccents(searchQuery.toLowerCase())
      );

    // Filtro por las opciones seleccionadas en catalogoOptionFilter
    const catalogoMatch = Object.entries(catalogoOptionFilter).every(
      ([category, options]) => {
        if (Object.keys(options).length === 0) {
          return true; // Si no hay opciones seleccionadas para esta categoría, no se filtra por ella
        }
        // Verificar si el producto tiene la categoría y si el valor coincide con alguna de las opciones, comparando en minúsculas y sin acentuaciones
        if (category === "colors") {
          return options.some((option) =>
            producto[category].some((color) =>
              removeAccents(color.name.toLowerCase()).includes(
                removeAccents(option.toLowerCase())
              )
            )
          );
        }
        if (category === "transmission") {
          return options.some((option) =>
            producto[category].some((transmission) =>
              removeAccents(transmission.toLowerCase()).includes(
                removeAccents(option.toLowerCase())
              )
            )
          );
        } else {
          return (
            producto[category] &&
            options
              .map((option) => removeAccents(option.toLowerCase()))
              .includes(
                removeAccents(
                  // Verificar si la categoría es un objeto con la propiedad 'name'
                  typeof producto[category] === "object" &&
                    producto[category] !== null
                    ? producto[category].name.toLowerCase()
                    : producto[category].toString().toLowerCase()
                )
              )
          );
        }
      }
    );

    // Filtro por las marcas seleccionadas en filterMarcas
    const filterMarcasMatch =
      filterMarcas.length === 0 || filterMarcas.includes(producto.brand.name.toLowerCase());

    // Filtro por las opciones seleccionadas en selectedOptionSubMenu
    const subMenuMatch = Object.entries(selectedOptionSubMenu).every(
      ([category, options]) => {
        if (options.length === 0) {
          return true;
        }

        if (category === "colors") {
          return options.some((option) =>
            producto[category].some((color) =>
              removeAccents(color.name.toLowerCase()).includes(
                removeAccents(option.toLowerCase())
              )
            )
          );
        }
        if (category === "transmission") {
          return options.some((option) =>
            producto[category].some((transmission) =>
              removeAccents(transmission.toLowerCase()).includes(
                removeAccents(option.toLowerCase())
              )
            )
          );
        } else {
          return (
            producto[category] &&
            options
              .map((option) => removeAccents(option.toLowerCase()))
              .includes(
                removeAccents(
                  producto[category].toString().toLowerCase() ||
                    producto[category].toLowerCase()
                )
              )
          );
        }
      }
    );

    return (
      searchQueryMatch && catalogoMatch && filterMarcasMatch && subMenuMatch
    );
  });

  //mostrar los autos filtrados
  const visibleCards = filteredProducts.slice(startIndex);

  const handleSetMarcas = (data) => {
    setFilterMarcas(data);
  }

  return (
    <Container className="container-fluid">
      {showModalFiltro && (
        <ModalFiltroMobileNuevos
          closeModal={closeModalFiltro}
          onSelectedValuesChange={handleSelectedValuesCatalogo}
        />
      )}
      <Navbar />
      <MarginNav />
      <TramaContainer>
        <div className="container">
          <StyledContainer>
            <div className="row">
              <StyleTitle className="offset-md-3 col-md-6  offset-2 col-8">
                <p>
                  Elegí y <span style={{ color: "#1a3eea" }}>personalizá </span>
                  tu próximo 0km.
                </p>
              </StyleTitle>
            </div>
            <div className="row">
              <MarcasMenu setFilterMarcas={handleSetMarcas} />
            </div>
            <div className="row">
              <ButtonNavMobile>
                <button
                  className="col-md-3"
                  onClick={() => handleButtonClick("auto-section")}
                >
                  Auto
                </button>
                <button
                  className="col-md-3"
                  onClick={() => handleButtonClick("pickup-section")}
                >
                  Pick-up
                </button>
                <button
                  className="col-md-3"
                  onClick={() => handleButtonClick("utilitario-section")}
                >
                  Utilitario
                </button>
                <button
                  className="col-md-3"
                  onClick={() => handleButtonClick("suv-section")}
                >
                  SUV
                </button>
              </ButtonNavMobile>
            </div>
            <div className="row">
              <FilterContainer>
                <div className="offset-md-4 col-md-4">
                  <Filter>
                    <SearchInput
                      type="text"
                      onChange={handleSearchInputChange}
                      value={searchQuery}
                      name="searchInput"
                      placeholder="¿Qué auto estás buscando?"
                      autoComplete="on"
                    />
                    <StyledFilterMenu>
                      <FilterIconImage
                        src={FilterIcon}
                        alt="Filter Icon"
                        onClick={() => setShowMenu(!showMenu)}
                      />
                      <FilterIconImageMobile
                        src={FilterIcon}
                        alt="Filter Icon"
                        onClick={() => setShowModalFiltro(true)}
                      />
                      <FilterMenuContainer $showMenu={showMenu}>
                        {filterOptionsWithModel.map((option) => (
                          <FilterMenuItem
                            key={option.value}
                            onMouseEnter={() => {
                              if (!isSubMenuOpen) {
                                setShowSubMenu(true);
                                setSelectedCategory(option.value);
                              }
                            }}
                            onClick={() => {
                              setShowMenu(false);
                            }}
                          >
                            {option.label}
                            <img
                              className="arrow-icon"
                              alt="arrow"
                              src={BlackArrow}
                            />
                          </FilterMenuItem>
                        ))}
                        {filterOptionsWithModel.map((option) => (
                          <SubMenu
                            key={option.label}
                            $showSubMenu={
                              showSubMenu && selectedCategory === option.value
                            }
                            onMouseEnter={() => setIsSubMenuOpen(true)}
                            onMouseLeave={() => setIsSubMenuOpen(false)}
                          >
                            {option.categories.map(
                              (category, categoryIndex) => (
                                <SubMenuItem
                                  key={categoryIndex}
                                  className={
                                    filterOptionSubMenuActive.includes(category)
                                      ? "isSelected"
                                      : ""
                                  }
                                  onClick={() =>
                                    handleSubMenuOptionChange(
                                      category,
                                      option.value
                                    )
                                  }
                                >
                                  {category}
                                </SubMenuItem>
                              )
                            )}
                          </SubMenu>
                        ))}
                      </FilterMenuContainer>
                    </StyledFilterMenu>
                  </Filter>
                </div>
              </FilterContainer>
            </div>
            <div className="row">
              <ButtonNav>
                <div className="offset-md-2 col-2">
                  <button onClick={() => handleButtonClick("auto-section")}>
                    Auto
                  </button>
                </div>
                <div className="col-2">
                  <button
                    onClick={() => handleButtonClick("utilitario-section")}
                  >
                    Utilitario
                  </button>
                </div>
                <div className="col-2">
                  <button onClick={() => handleButtonClick("suv-section")}>
                    SUV
                  </button>
                </div>
                <div className="col-2">
                  <button onClick={() => handleButtonClick("pickup-section")}>
                    Pick-up
                  </button>
                </div>
              </ButtonNav>
            </div>
          </StyledContainer>
        </div>
      </TramaContainer>
      <div className="container">
        <Main>
          <div className="row">
            <AutoList id="auto-section">
              <ListTitle>AUTO</ListTitle>
              {visibleCards
                .filter((card) => card.category.name.toLowerCase() === "auto")
                .map((card) => (
                  <div
                    key={card._id}
                    className="col-md-3 col-6"
                    onClick={() => handleCardClick(card)}
                  >
                    <Card
                      comparadorAbierto={comparadorAbierto}
                      producto={card}
                      originalId={card._id}
                      autosSeleccionados={autosSeleccionados}
                    />
                  </div>
                ))}
            </AutoList>
          </div>
          <div className="row">
            <SuvList className="suv-list" id="suv-section">
              <ListTitle>SUV</ListTitle>
              {visibleCards
                .filter((card) => card.category.name.toLowerCase() === "suv")
                .map((card) => (
                  <div
                    key={card._id}
                    className="col-md-3 col-6"
                    onClick={() => handleCardClick(card)}
                  >
                    <Card
                      comparadorAbierto={comparadorAbierto}
                      producto={card}
                      originalId={card._id}
                      autosSeleccionados={autosSeleccionados}
                    />
                  </div>
                ))}
            </SuvList>
          </div>
          <div className="row">
            <PickupList className="pickup-list" id="pickup-section">
              <ListTitle>PICK-UP</ListTitle>
              {visibleCards
                .filter(
                  (card) => card.category.name.toLowerCase() === "pick up"
                )
                .map((card) => (
                  <div
                    key={card._id}
                    className="col-md-3 col-6"
                    onClick={() => handleCardClick(card)}
                  >
                    <Card
                      comparadorAbierto={comparadorAbierto}
                      producto={card}
                      originalId={card._id}
                      autosSeleccionados={autosSeleccionados}
                    />
                  </div>
                ))}
            </PickupList>
          </div>
          <div className="row">
            <PickupList className="pickup-list" id="utilitario-section">
              <ListTitle>UTILITARIO</ListTitle>
              {visibleCards
                .filter(
                  (card) => card.category.name.toLowerCase() === "utilitario"
                )
                .map((card) => (
                  <div
                    key={card._id}
                    className="col-md-3 col-6"
                    onClick={() => handleCardClick(card)}
                  >
                    <Card
                      comparadorAbierto={comparadorAbierto}
                      producto={card}
                      originalId={card._id}
                      autosSeleccionados={autosSeleccionados}
                    />
                  </div>
                ))}
            </PickupList>
          </div>
          {/* <div className="row">
            <OtherList className="other-list" id="other-section">
              <ListTitle>OTROS</ListTitle>
              {visibleCards
                .filter(
                  (card) =>
                    !["auto", "suv", "pick-up"].includes(
                      card.category.name.toLowerCase()
                    )
                )
                .map((card) => (
                  <div
                    key={card._id}
                    className="col-md-3 col-6"
                    onClick={() => handleCardClick(card)}
                  >
                    <Card
                      comparadorAbierto={comparadorAbierto}
                      producto={card}
                      originalId={card._id}
                      autosSeleccionados={autosSeleccionados}
                    />
                  </div>
                ))}
            </OtherList>
          </div> */}
        </Main>
      </div>
      <div className="margin-mobile-comparador"></div>
      <ComparadorMobile
        autosSeleccionados={colector}
        isOpen={comparadorAbierto}
        toggleComparador={() => {
          setAutosSeleccionados([]);
          setComparadorAbierto(!comparadorAbierto);
        }}
        onRemoveCar={(id) => {
          const updatedAutos = [...colector].filter((car, i) => i !== id);
          setColector(updatedAutos);
        }}
      />
      <Comparador
        autosSeleccionados={colector}
        isOpen={comparadorAbierto}
        toggleComparador={() => {
          setAutosSeleccionados([]);
          setComparadorAbierto(!comparadorAbierto);
        }}
        onRemoveCar={handleRemoveCar}
      />
    </Container>
  );
};

export default Catalago0km;
