import React, { useEffect, useState } from "react";
import Navbar from "../componentes/Navbar";
import CatalogoMenu from "../componentes/CatalogoMenu";
import Comparador from "../componentes/Comparador";
import Card from "../componentes/CatalogoUsadosCard";
import Lupa from "../images/icon_lupa.png";
import trama from "../images/trama.png";
import FilterIcon from "../images/filter_icon.png";
import ENDPOINTS from "../apiEndpoints";
import styled from "styled-components";
import "../styles.css";
import ComparadorMobile from "../componentes/ComparadorMobile";
import ModalFiltroMobile from "../componentes/ModalCatalogoFiltroMobile";
import axiosInstance from "../utils/ContextAxios";
import Spinner from "../componentes/Loader";


const MarginNav = styled.div`
  display: block;
  background-color: black;
  padding-top: 114px;
  @media (max-width: 576px) {
    padding-top: 48px;
  }
`;
const TramaContainer = styled.div`
  background-image: url(${trama}); /* Utiliza la imagen trama como fondo */
  background-size: cover; /* Ajusta el tamaño de la imagen para cubrir todo el contenedor */
  background-position: center; /* Centra la imagen en el contenedor */
  @media (max-width: 576px) {
    height: 189px;
    margin-bottom: 50px;
  }
`;

const Container = styled.div`
  padding-right: 0px !important;
  padding-left: 0px !important;
  button {
    outline: none;
  }
`;

const BannerTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 288px;
  padding: 0;
  font-family: var(--font-family);

  .title {
    margin-top: 80px;
    font-weight: 700;
    font-size: 34px;
    text-align: center;
    line-height: 34px;
  }

  .infobanner {
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    margin-top: 15px;
  }

  span {
    color: var(--prime-color);
  }

  @media (max-width: 576px) {
    height: 189px;
    align-items: center;

    .title {
      text-align: center;
      font-weight: 700;
      font-size: 21px;
      line-height: 21px;
      margin-top: 30px;
      width: 65%;
    }
    .infobanner {
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      width: 100%;
      padding: 0px 18px;
      padding-top: 5px;
      margin: 0;
    }
  }
`;

const StyledBox = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;

  .aside-menu {
    height: 100%;
  }

  @media (max-width: 576px) {
    display: block;
  }
`;

const Filter = styled.div`
  display: flex;
  height: 50px;
  width: 100%;
  margin: 64px 0px;
  justify-content: flex-start;

  input {
    box-shadow: 0px 2px 4px 0px #00000035;
    width: 100%;
    height: 45px;
    border: none;
    border-radius: 5px;
    background-color: var(--gris-claro);
    padding-left: 48px; /* Ajusta el padding izquierdo para dar espacio al icono */
    background-image: url(${Lupa}); /* Set the background image to the magnifying glass icon */
    background-repeat: no-repeat; /* Prevent the icon from repeating */
    background-position: 15px center; /* Adjust the position of the icon */
    outline: none;
  }
  @media (max-width: 576px) {
    display: none;
  }
`;

const FilterMobile = styled.div`
  display: none;
  width: 100%;
  margin-top: -20px;
  justify-content: center;

  input {
    width: 246px;
    height: 37px;
    /* box-shadow: 0px 2px 4px 0px #00000035; */
    background-color: var(--gris-invisible);
    padding-left: 48px; /* Ajusta el padding izquierdo para dar espacio al icono */
    background-image: url(${Lupa}); /* Set the background image to the magnifying glass icon */
    background-repeat: no-repeat; /* Prevent the icon from repeating */
    background-position: 15px center; /* Adjust the position of the icon */
    outline: none;
  }
  @media (max-width: 576px) {
    display: flex;
    margin-bottom: 45px;
  }
`;
const FilterIconImage = styled.img`
  display: block;
  width: 15px;
  height: auto;
  padding-top: 13px;
  margin: auto;
  cursor: pointer;
`;
const StyledFilterMenu = styled.div`
  position: relative;
  background-color: var(--gris-medio);
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  &:hover {
    display: block;
    transition: all 0.3s ease-out;
    background-color: var(
      --prime-color
    ); /* Cambiar el color al color principal en hover */
  }

  @media (max-width: 576px) {
    width: 73px;
    height: 37px;
  }
`;
const SearchInput = styled.input`
  display: flex;
  background-color: white;
  border: none;
  padding-left: 40px;
  outline: none;
  border-radius: 6px 0px 0px 6px;
  background-image: url(${Lupa}); /* Set the background image to the magnifying glass icon */
  background-repeat: no-repeat; /* Prevent the icon from repeating */
  background-position: 10px center; /* Adjust the position of the icon */
  width: 246px;
  height: 37px;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
`;
const StyledButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 20px 0px;

  @media (max-width: 576px) {
    display: none;
  }
`;

const StyledButton = styled.button`
  font-family: var(--font-family);
  font-weight: 700;
  line-height: 20px;
  font-size: 14px;
  height: 35px;
  width: 48%;
  padding: 0;
  margin: 0;
  justify-content: center;
  text-align: center;
  align-items: center;
  border: 1px solid var(--prime-color);
  background-color: transparent;
  color: var(--prime-color);
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    color: white;
    background-color: #595e62;
    border-color: transparent;
  }
  ${({ disabled }) =>
    disabled &&
    `
    color: #a1a1a1;
    background-color: #ffffff;
    border-color: #a1a1a1;
     &:hover { color: #a1a1a1;
    background-color: #ffffff;
    border-color: #a1a1a1;}
  `}
`;

const CatalagoUsados = ({ onFilterChange }) => {
  // Estado para controlar si el comparador está abierto o cerrado
  const [comparadorAbierto, setComparadorAbierto] = useState(false);
  //mostrar menu-filter
  const [showModalFiltro, setShowModalFiltro] = useState(false);
  // Estado para almacenar la información del auto seleccionado
  const [autosSeleccionados, setAutosSeleccionados] = useState([]);
  const [colector, setColector] = useState([]);
  //producto
  const [autosLista, setAutosLista] = useState("");

  //opciones del catalogo-filter
  const [catalogoOptionFilter, setCatalogoOptionFilter] = useState({});
  //estado del filtro
  const [searchQuery, setSearchQuery] = useState("");

  //mostrar menu-filter
  const [showMenu, setShowMenu] = useState(false);

  //card que se van a mostrar
  const [startIndex, setStartIndex] = useState(0);

  const cardsPerRow = 3;
  const rowsPerPage = 4;

  const totalCardsPerPage = cardsPerRow * rowsPerPage;
  const isBackButtonDisabled = startIndex === 0;

  //Funcion para cargar el auto al comparador y evitar que haga una redireccion
  const handleCardClick = (card) => {
    if (comparadorAbierto && autosSeleccionados.length < 4) {
      setColector(prev => {
        const exists = prev.find(a => a._id === card._id);

        if (!exists) {
          console.log("selectec", [...prev, card]);
          return [...prev, card];  
        } else {
          return [...prev]
        }
      })
    } else {
      console.log("Redireccionar a la URL del auto:", card._id);
    }
  };

  const handleRemoveCar = (id) => {
    const filteredCars = colector.filter(car => car._id !== id)
    setColector([...filteredCars])
  }

  const handleSelectedValuesCatalogo = (values) => {
    setCatalogoOptionFilter(values);

    // Obtener campos seleccionados y opciones
    const camposSeleccionados = Object.keys(catalogoOptionFilter);

    const opcionesPorCampo = camposSeleccionados.map((campo) => {
      return {
        campo: campo,
        opciones: catalogoOptionFilter[campo],
      };
    });

    console.log(opcionesPorCampo, "Opciones del filtro"); // Puedes hacer más acciones con esta información
  };

  //valor de la busqueda
  const handleSearchInputChange = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
  };

  //mostrar mas o menos cards--------
  const handleShowMoreCards = () => {
    const newStartIndex = startIndex + totalCardsPerPage;
    if (newStartIndex < filteredProducts.length) {
      setStartIndex(newStartIndex);
    }
  };

  //cerrar modal filtro
  const closeModalFiltro = () => {
    setShowModalFiltro(false);
  };

  const handleShowPreviousCards = () => {
    const newStartIndex = startIndex - totalCardsPerPage;
    if (newStartIndex >= 0) {
      setStartIndex(newStartIndex);
    }
  };
  //--------------
  /*------api-------*/
  //pedir datos
  useEffect(() => {
    const fetchData = async () => {
      try {
        axiosInstance.get(ENDPOINTS.GET_USED_CARS).then((response)=>{
            const {data:cars} = response.data;
            setAutosLista(cars);
        } )        
      } catch (error) {
        console.log("Error:", error);
      }
      //get/modelo/version
    };

    fetchData();
  }, []);

  if (!autosLista || autosLista.length === 0) {
    return <Spinner />; // Mostrar un spinner mientras se cargan los datos
  }
  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const filteredProducts = autosLista.filter((producto) => {
    // Filtro por searchQuery
    const searchQueryMatch =
      removeAccents(producto.modelo.name.toLowerCase()).includes(
        removeAccents(searchQuery.toLowerCase())
      ) ||
      removeAccents(producto.brand.name.toLowerCase()).includes(
        removeAccents(searchQuery.toLowerCase())
      ) ||
      removeAccents(producto.version.name.toLowerCase()).includes(
        removeAccents(searchQuery.toLowerCase())
      );

    // Filtro por las opciones seleccionadas en catalogoOptionFilter
    const catalogoMatch = Object.entries(catalogoOptionFilter).every(
      ([category, options]) => {
        if (Object.keys(options).length === 0) {
          return true; // Si no hay opciones seleccionadas para esta categoría, no se filtra por ella
        }

        if (category === "color") {
          return options.some((option) =>
            removeAccents(producto[category].name.toLowerCase()).includes(
              removeAccents(option.toLowerCase())
            )
          );
        }
        if (category === "mileage") {
          return options.some((option) => {
            const mileage = producto.mileage;

            // Mapear las opciones a rangos de kilometraje
            if (option === "Menos de 50,000 km") {
              return mileage < 50000;
            } else if (option === "50,000 - 100,000 km") {
              return mileage >= 50000 && mileage <= 100000;
            } else if (option === "Más de 100,000 km") {
              return mileage > 100000;
            }
            return false;
          });
        }
        if (category === "transmission") {
          return options.some((option) =>
            producto[category].some((transmission) =>
              removeAccents(transmission.toLowerCase()).includes(
                removeAccents(option.toLowerCase())
              )
            )
          );
        } else {
          return (
            producto[category] &&
            options
              .map((option) => removeAccents(option.toLowerCase()))
              .includes(
                removeAccents(
                  // Verificar si la categoría es un objeto con la propiedad 'name'
                  typeof producto[category] === "object" &&
                    producto[category] !== null
                    ? producto[category].name.toLowerCase()
                    : producto[category].toString().toLowerCase()
                )
              )
          );
        }
      }
    );

    return searchQueryMatch && catalogoMatch;
  });
  console.log("Filtered Products:", filteredProducts);

  // Utiliza filteredProducts en lugar de autosLista para mostrar los autos filtrados
  const visibleCards = filteredProducts.slice(
    startIndex,
    startIndex + totalCardsPerPage
  );

  return (
    <Container className="container-fluid">
      <Navbar />
      <MarginNav />
      {showModalFiltro && (
        <ModalFiltroMobile
          closeModal={closeModalFiltro}
          onSelectedValuesChange={handleSelectedValuesCatalogo}
        />
      )}
      <TramaContainer>
        <div className="container">
          <div className="row">
            <BannerTitle className=" offset-md-3 col-md-6">
              <p className="title">
                Autos usados,
                <span> calidad que perdura </span>
              </p>
              <p className="infobanner">
                Recorré nuestro catálogo y conocé la historia detrás de cada
                vehículo, su calidad comprobada y la garantía de un rendimiento
                duradero.
              </p>
            </BannerTitle>
            <FilterMobile className="col-md-6">
              <SearchInput
                type="text"
                onChange={handleSearchInputChange}
                value={searchQuery}
                name="searchQueryMobile"
                placeholder="¿Qué auto estás buscando?"
              />
              <StyledFilterMenu>
                <FilterIconImage
                  src={FilterIcon}
                  alt="Filter Icon"
                  onClick={() => setShowModalFiltro(true)}
                />
              </StyledFilterMenu>
            </FilterMobile>
          </div>
        </div>
      </TramaContainer>
      <div className="container">
        <div className="row">
          <Filter className="col-md-6">
            <input
              type="text"
              name="searchQuery"
              placeholder="¿Qué auto estás buscando?"
              onChange={handleSearchInputChange}
              value={searchQuery}
            />
          </Filter>
        </div>
        <div className="row">
          <StyledBox>
            <div className="aside-menu col-md-3">
              <CatalogoMenu
                onSelectedValuesChange={handleSelectedValuesCatalogo}
              />
            </div>
            <div className="catalogo-grid col-md-9">
              <CardsContainer>
                <div className="row">
                  {visibleCards.map((card, index) => (
                    <div
                      key={index}
                      className="col-6 col-md-4"
                      onClick={() => handleCardClick(card)}
                    >
                      <Card
                        comparadorAbierto={comparadorAbierto}
                        producto={card}
                        originalId={card._id}
                      />
                    </div>
                  ))}
                </div>
                <div className="row justify-content-center">
                  <StyledButtonContainer className="col-4">
                    <StyledButton
                      onClick={handleShowPreviousCards}
                      disabled={isBackButtonDisabled}
                    >
                      Anterior
                    </StyledButton>
                    <StyledButton
                      onClick={handleShowMoreCards}
                      disabled={
                        startIndex + totalCardsPerPage >=
                        filteredProducts.length
                      }
                    >
                      Siguiente
                    </StyledButton>
                  </StyledButtonContainer>
                </div>
              </CardsContainer>
            </div>
          </StyledBox>
        </div>
      </div>
      <ComparadorMobile
        autosSeleccionados={colector}
        isOpen={comparadorAbierto}
        toggleComparador={() => {
          setAutosSeleccionados([]);
          setComparadorAbierto(!comparadorAbierto);
        }}
        onRemoveCar={(id) => {
          const updatedAutos = [...colector].filter((car, i) => i !== id);
          setColector(updatedAutos);
        }}
      />
      <Comparador
        autosSeleccionados={colector}
        isOpen={comparadorAbierto}
        toggleComparador={() => {
          setAutosSeleccionados([]);
          setComparadorAbierto(!comparadorAbierto);
        }}
        onRemoveCar={handleRemoveCar}
      />

    </Container>
  );
};

export default CatalagoUsados;
