import React, { useState } from "react";
import Navbar from "../componentes/Navbar";
import Footer from "../componentes/Footer";
import IconOne from "../images/icon_1blue.png";
import trama from "../images/trama.png";
import styled from "styled-components";
import "../styles.css";
import FinanciacionHeroBanner from "../componentes/FinanciacionHeroBanner";
import FooterMobile from "../componentes/FooterMobile";
import WhatsAppBtn from "../componentes/WhatsAppBtn";
import axios from "axios";
import Swal from "sweetalert2";
import ENDPOINTS from "../apiEndpoints";

const MarginNav = styled.div`
  display: block;
  background-color: black;
  padding-top: 114px;
  @media (max-width: 576px) {
    padding-top: 48px;
  }
`;
const ContainerStyles = styled.div`
  padding-right: 0px !important;
  padding-left: 0px !important;

  button {
    outline: none;
  }
`;

const TramaContainer = styled.div`
  background-image: url(${trama}); /* Utiliza la imagen trama como fondo */
  background-size: cover; /* Ajusta el tamaño de la imagen para cubrir todo el contenedor */
  background-position: center; /* Centra la imagen en el contenedor */
`;
const StyledBannerTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 288px;
  /* background-color: #f6f6f6; */
  align-items: center;
  font-family: var(--font-family);
  text-align: center;

  .title {
    padding: 0;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
  }

  p {
    margin-top: 80px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
  @media (max-width: 576px) {
    height: 165px;
    text-align: center;
    .title {
      font-size: 21px;
      line-height: 21px;
      text-align: center;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      margin-top: 22px;
    }
  }
`;
const StyledFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  p {
    font-weight: 700;
    line-height: 26px;
  }

  label {
    font-size: 18px;
    font-weight: 700;
    font-family: var(--font-family);
    line-height: 20px;
    margin-right: 15px;
  }

  .form-item {
    display: flex;
    flex-direction: column;
  }

  .radio-inputs {
    /* text-align: center; */
    display: flex;
    padding: 10px;
    margin: 5px;
  }

  .radio-inputs label {
    font-weight: 400;
    font-size: 16px;
  }

  .title-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    padding: 0;
  }
  .title {
    display: flex;
    font-size: 20px;

    img {
      width: 18px;
      height: 18px;
      margin-right: 5px;
      margin-top: 3px;
    }
  }

  .santander-logo {
    margin-top: -40px;
  }

  .btn-group {
    display: flex;

    button {
      border-radius: 8px;
      font-size: 14px;
      font-weight: 500;
      margin-right: 5px;
      height: 40px;
    }
  }

  .confirm-btn {
    margin: 15px;
    width: 305px;
  }
  .button-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    font-size: 14px;
    line-height: 13px;
    button {
      width: 100%;
      padding: 0;
    }
  }
  @media (max-width: 576px) {
    margin-top: 20px;
    .icon-title {
      display: none;
    }
    .title {
      display: flex;
      justify-content: space-evenly;
      font-size: 18px;
      text-align: center;
    }
    label {
      font-size: 15px;
      line-height: 15px;
    }
    .button-container {
      margin-bottom: 15px;
    }

    .title-row {
      margin-bottom: 20px;
    }
  }
`;

const Financiacion = () => {
  const [formulario, setFormulario] = useState({
    nombre: "",
    email: "",
    codigoPostal: "",
    telefono: "",
    mensaje: "",
  });

  // Función para manejar el cambio en los inputs
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormulario((prevFormulario) => ({
      ...prevFormulario,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Formulario:", formulario);

    // Set data fields
    const data = {
      fox_api_key: process.env.REACT_APP_FOX_API_KEY || 'ABCDE-FGHIJ-KLMNO',
      fox_api_origin: process.env.REACT_APP_FOX_API_ORIGIN || 'ads',
      fox_api_business_type_id: 1,
      fox_api_first_name: formulario.nombre,
      fox_api_last_name: '',
      fox_api_phone: formulario.telefono,
      fox_api_cellphone: formulario.telefono,
      fox_api_email: formulario.email,
      fox_api_notes: formulario.mensaje || "Sin mensaje",
      fox_api_brand: 'Chery',
      fox_api_model: 'Tiggo',
      fox_api_country: 'Argentina',
      fox_api_state: 'Buenos Aires',
      fox_api_city: formulario.codigoPostal,
      fox_api_url: window.location,
      fox_api_dni: '00000000',
      fox_api_vendor: 'Mi nombre de proveedor'
    };

    // Enviar datos del formulario a través de Axios
    axios.post(ENDPOINTS.CRM_API, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then((res) => {
      console.log(res)
      const {data} = res
      if(data.error === 1){
        Swal.fire({
          icon: 'error',
          title: '¡Ha ocurrido un error!',
          text: data.message,
          showConfirmButton: false,
          iconColor: '#dc3545',
          timer: 2000,
        });
      }else {
        Swal.fire({
          icon: 'success',
          title: '¡Formulado enviado con éxito!',
          text: 'Un asesor se comunicará contigo pronto.',

          iconColor: '#1A3EEA',
          showConfirmButton: false,
          timer: 2000,
        });
        setFormulario({
        nombre: "",
        email: "",
        codigoPostal: "",
        telefono: "",
        mensaje: "",
      });
      }
      
    }).catch(err => {
      console.error(err);
      Swal.fire({
        icon: 'error',
        title: '¡Ha ocurrido un error!',
        text: 'No se han podido enviar los datos',
        showConfirmButton: false,
        iconColor: '#dc3545',
        timer: 2000,
      });
    })

  };

  return (
    <ContainerStyles className="container-fluid">
      <Navbar />
      <MarginNav />
      <TramaContainer>
        <div className="container">
          <div className="row">
            <StyledBannerTitle>
              <p className="col-md-6">
                <span className="title">Vos decidís cómo pagás </span> <br />
                <br />
                Te ofrecemos soluciones de financiación personalizadas para que
                puedas dar el primer paso hacia la conducción de tu nuevo auto.
              </p>
            </StyledBannerTitle>
          </div>
        </div>
      </TramaContainer>
      <div className="container">
        <div className="row">
          <StyledFormContainer className="offset-md-2 col-md-8 offset-1 col-10">
            <form className="user-form" onSubmit={handleSubmit}>
              <div className="title-row col-md-9">
                <div className="title">
                  <img src={IconOne} alt="" className="icon-title" />
                  <p>Dejanos tus datos y nos contactamos a la brevedad</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="nombre" className="form-label">
                      Nombre Completo
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="nombre"
                      autoComplete="none"
                      required
                      onChange={handleChange}
                      value={formulario.nombre}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="codigoPostal" className="form-label">
                      Codigo Postal
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="codigoPostal"
                      autoComplete="none"
                      required
                      onChange={handleChange}
                      value={formulario.codigoPostal}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      E-mail
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      autoComplete="none"
                      required
                      onChange={handleChange}
                      value={formulario.email}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="telefono" className="form-label">
                      Celular
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      autoComplete="none"
                      id="telefono"
                      required
                      onChange={handleChange}
                      value={formulario.telefono}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="mensaje" className="form-label">
                      Mensaje
                    </label>
                    <textarea
                      className="form-control"
                      id="mensaje"
                      autoComplete="none"
                      onChange={handleChange}
                      value={formulario.mensaje}
                      required
                    />
                  </div>
                </div>
                <div className="button-container">
                  <button
                    type="submit"
                    className="confirm-btn primary-btn-big col-md-4 col-11"
                  >
                    Quiero la mejor opción financiada
                  </button>
                </div>
              </div>
            </form>
          </StyledFormContainer>
        </div>
        <FinanciacionHeroBanner />
      </div>
      <WhatsAppBtn />
      <Footer />
      <FooterMobile />
    </ContainerStyles>
  );
};

export default Financiacion;
